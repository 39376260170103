
  import { Options, Vue, prop } from "vue-class-component";
  import Checkbox from "primevue/checkbox";
  import LpRadio from "@/app/ui/components/lp-radio/index.vue";
  import BottomSheet from "@/app/ui/components/bottom-sheet/index.vue";
  import { DashboardController } from "@/app/ui/controllers/DashboardController";
  
  class Prop {
    isDisabled = prop<boolean>({
      type: Boolean,
      default: false
    });
    optionDeliveryType = prop<any>({
        required: true
    });
    optionProduct = prop<any>({
        required: true
    });
    labelProduct = prop<any>({
        required: true
    });
  }
  
  @Options({
    emits: ["change", "update:modelValue"],
    components: {
      Checkbox,
      LpRadio,
      BottomSheet
    }
  })
  export default class AdvanceFilter extends Vue.with(Prop) {
    isMobile = false;
  
    mounted(): void {
      this.setMobileSize();
  
      window.addEventListener("resize", () => {
        this.setMobileSize();
      });
      this.filterData.product = this.filterClientDashboard.product.value
      this.filterData.deliveryType = this.filterClientDashboard.deliveryType.value
    }
    isChevron = false;
    openFilter = false;
    filterData: any = {
        product: "",
        deliveryType: "",
    };
    get optionsDeliveryType() {
        return this.optionDeliveryType.map((opt:any) => opt.value)
    }
    get labelDeliveryType() {
        return this.optionDeliveryType.map((opt:any) => opt.name)
    }
  
    get filterClientDashboard() {
        return DashboardController.filterCorporateDashboard;
    }
  
    async resetFilter() {
        DashboardController.filterCorporateDashboard.product = {name: 'Semua Layanan', value: 'all'}
        DashboardController.filterCorporateDashboard.deliveryType = {name: "Semua Pengiriman", value: "all"}
        this.onChange();
    }
  
    get sumFilter() {
      return (
        (DashboardController.filterCorporateDashboard.product.value !== "all" ? 1 : 0) +
        (DashboardController.filterCorporateDashboard.deliveryType.value !== "all" ? 1 : 0)
      );
    }
  
    onChange() {
      this.$emit("change", this.filterClientDashboard);
      this.$emit("update:modelValue", this.filterClientDashboard);
  
      if (this.isMobile) {
        this.openFilter = false;
      }
    }
  
    setMobileSize() {
      this.isMobile = window.innerWidth <= 599;
    }

    setDeliveryType() {
        DashboardController.filterCorporateDashboard.deliveryType = this.optionDeliveryType.find((item:any) => item.value === this.filterData.deliveryType);
    };
  }
  